import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "../styles/codestyle";
import rangeParser from "parse-numeric-range";
import Image from "./image";
import { Link } from "gatsby";
import ApiDropdown from "./apidropdown";

const calculateLinesToHighlight = raw => {
  const lineNumbers = rangeParser(raw);
  if (lineNumbers) {
    return index => lineNumbers.includes(index + 1);
  } else {
    return () => false;
  }
};

const copyToClipboard = str => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str).then(
      function () {
         // console.log("Copying to clipboard was successful!");
      },
      function (err) {
       // console.error("Could not copy text: ", err);
      }
    );
  } else if (window.clipboardData) {
    // Internet Explorer
    window.clipboardData.setData("Text", str);
  }
};

const Code = props => {
  const [isCopied, setIsCopied] = React.useState(false);
  const className = props.children.props.className || "";
  const code = props.children.props.children.trim();
  const language = className.replace(/language-/, "");
  const file = props.children.props.file;
  const requestList = props.data
  const updateFunc = props.updateReq
  const highlights = calculateLinesToHighlight(
    props.children.props.highlights || ""
  );

  return (
    <div
      style={{
        background: "#063A4F",
        borderRadius: "0.5rem",
        //marginTop: "2rem",
        //fontSize: "12px",
        //marginBottom: "2rem",
        paddingLeft: "1.5rem",
        fontFamily: "'Oxygen Mono', monospace"
      }}

      className="my-3 mb-2 text-xs p-1 pt-0 pr-0 md:text-sm"
    >
      <div style={{ display: "flex", borderTopLeftRadius: "0.5rem",  borderTopRightRadius: "0.5rem", position: "relative", background: "#002736", marginLeft: "-1.5rem", }}>
        <div
          style={{
            marginRight: "1rem",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            textTransform: "uppercase",
            color: "#00B8C2",
            fontFamily: "'Oxygen Mono', monospace",
            fontWeight: "900",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}

          className="text-sm"
        >
          {`${language}`}
        </div>
        <div
          style={{
            color: "#9d9d9d",
            fontFamily: "'Inter', monospace",
            fontStyle:"15px",
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {file && `${file}`}
        </div>
         <div style={{ flexGrow: "1" }}></div>
        {(!isCopied)?
        <Link to="#"
         onClick={(e) => {
            e.preventDefault();
            copyToClipboard(code);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000);
          }}>
          <Image 
          src="copybutton.svg" 
           style={{
            marginRight: "0.5rem",
            marginTop: "0.5rem",
            padding: "8px 12px",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
            color: "#E2E8F0",
            fontSize: "12px",
            fontFamily: "sans-serif",
            lineHeight: "1",
            minHeight: "15px",
            minWidth: "16px",
          }} alt="Copy button" />
          </Link>:
          <div style={{
            "fontFamily":"Inter",
            "color":"#000",
            "background":"#fff",
            "textAlign":"center",
            "borderRadius":"3px",
            "width":"61px",
            "height":"maxContent",
            "paddingTop":"3px",
            "paddingBottom":"3px",
            "margin":"auto"
          }}
          >Copied</div>
        }   
      </div>
      <div
        style={{
          overflow: "auto",
          background: "#063A4F",
          borderRadius: "0.5rem",
          maxHeight:"500px",
        }}
      >
        <Highlight
          {...defaultProps}
          code={code}
          language={language}
          theme={theme}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre
              className={className}
              style={{
                ...style,
                backgroundColor: "transparent",
                float: "left",
                minWidth: "100%",
                paddingTop:  "0.9rem",
                paddingBottom:  "0.9rem",
                overflowWrap:" break-word",
                overflowY:"auto"

              }}
            >
              {tokens.map((line, i) => (
                <div
                  {...getLineProps({ line, key: i })}
                  style={{
                    background: highlights(i) ? "#00f5c426" : "transparent",
                    display: "block",
                  }}
                >
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </div>
    </div>
  );
};

export default Code;