/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ApiDropdown(props) {
    //[request, setRequest] = useState("")
    const reqRespFunc = props.updatefunc
    let requestList = props.reqData
    const setfile = props.setfile
    const setCode = props.setCode

  return (
    <>
    {(requestList && requestList.length > 1)?
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm px-4 py-2 text-sm focus:outline-none" style={{
            "paddingLeft":"0px",
            "marginLeft":"-7px"
        }}>
        <ChevronDownIcon className="mr-1 ml-2 h-8 w-8" aria-hidden="true" style={{
            "color":"#9d9d9d",
            }} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" style={{
            "right":"-72px"
        }}>
        <div className="py-1">
        { requestList && Object.entries(requestList).map(([mkey, mitem], inddexx) => {
           
          return(
                <Menu.Item key={inddexx}>
                {({ active }) => (
                    <button onClick={()=>{
                      setCode( mitem.body)
                      //reqRespFunc(mitem.name,mitem.body);
                       setfile(("" + mitem.name).toUpperCase())
                      }}
                    style={{width:"100%"}}
                    >
                    <div
                    className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-2 py-2 text-sm'
                    )}
                    style={{
                        "fontFamily":'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                        "fontSize":"11px",
                        "textAlign":"left"
                    }}
                    >
                    {mitem.name}
                    </div>
                    </button>
                )}
                </Menu.Item>
          )
            })}
        </div>
        </Menu.Items>
      </Transition>
    </Menu>
    :""}
    </>

  )
}
