
import React from 'react';

function Image ({ src = false, alt, style, classes ='', onMouseOut=false, onMouseOver=false }) {

    let mage = (! src )?"":require('../images/' + src).default
    let arr = {}
    if(onMouseOut){  arr.onMouseOut = () => onMouseOut() }
    if(onMouseOver){  arr.onMouseOver = () => onMouseOver()   }
    
     return (
        <>
            <img src={mage} style={style} alt={alt} className={ classes}  {...arr} />
        </>
    );

}
export default Image
